import React from "react";
import Amplify, { API } from "aws-amplify";
import ReactDataSheet from "react-datasheet";
import IconButton from "@material-ui/core/IconButton";
import AddBox from "@material-ui/icons/AddBox";
import PrintIcon from "@material-ui/icons/Print";
import DeleteIcon from "@material-ui/icons/Delete";
import SettingsIcon from "@material-ui/icons/Settings";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import sampleBarcode from "./sampleBarcode.jpg";

import "./zebraDirectLabelPrinterComponent.css";
import "react-datasheet/lib/react-datasheet.css";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Portal from "@material-ui/core/Portal";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function PrintLabelForm(props) {
  const [formValues, setFormValues] = React.useState({
    printer: "",
    zplTemplate: "",
    notes: "",
  });
  const [retryOpen, setRetryOpen] = React.useState(false);
  const [retryIndex, setRetryIndex] = React.useState(null);
  const [retryTotal, setRetryTotal] = React.useState(null);

  /*
  open={this.state.printLabelFormOpen}
  handlePrintLabelFormOpen={this.handlePrintLabelFormOpen}
  grid={this.state.grid}
  printers=
  zplTemplates=
  showSnack={this.showSnack}
  showBackdrop={this.showBackdrop}
  hideBackdrop={this.hideBackdrop}
  */

  const handleInputChange = (e) => {
    console.log("*handleInputChange*");
    console.log(e);
    const { name, value } = e.target;
    console.log({ name, value });
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formValues);

    //complete any field validation in reality this case shouldn't occur
    if (formValues.printer == "" || formValues.zplTemplate == "") {
      props.showSnack(
        true,
        "Please fill out printer and label template fields before submitting",
        "error"
      );
      return;
    }

    props.handlePrintLabelFormOpen(false);
    props.showBackdrop();

    let header_list = [];
    for (let i = 0; i < props.grid[0].length; i++) {
      header_list.push(props.grid[0][i]["value"] + ":");
    }
    let field_list = [];
    //extract strain batch ids from selected indexes
    for (let i = 1; i < props.grid.length; i++) {
      let rowContainsData = false;
      let row_list = [];
      for (let j = 0; j < props.grid[i].length; j++) {
        if (props.grid[i][j]["value"] != "") {
          rowContainsData = true;
        }
        row_list.push(props.grid[i][j]["value"]);
      }
      if (rowContainsData) {
        field_list.push(row_list);
      }
    }
    /*
    body = json.loads(event['body'])
        printer_id: body['printerId']
        zpl_template_id = body['zplTemplateId']
        submitted_by = body['submittedBy'] #email of cognito user
        headers_list = body['headers']
        fields_list = body['fields'] #
     */
    const myInit = {
      headers: { Authorization: false },
      body: {
        headers: header_list.slice(1, header_list.length),
        fields: field_list,
        submittedBy: props.userEmail,
        printerId: formValues.printer,
        zplTemplateId: formValues.zplTemplate,
        notes: formValues.notes,
      },
    };
    console.log(myInit);

    try {
      const resp = await API.post("Print", "/newprintjob", myInit);
      console.log("**PRINT RESPONSE**");
      console.log(resp);
      if (resp.job_status == "error") {
        props.showSnack(true, resp.message, "error");
        props.handlePrintLabelFormOpen(true);
        props.hideBackdrop();
      } else if (resp.job_status == "complete") {
        props.showSnack(true, resp.message, "success");
        props.handlePrintLabelFormOpen(false);
        props.hideBackdrop();
      } else if (resp.job_status == "partial") {
        props.showSnack(
          true,
          "Error encountered after printing " +
            resp.label_index +
            " labels:\n" +
            resp.message,
          "warning"
        );
        props.handlePrintLabelFormOpen(false);
        props.hideBackdrop();
        setRetryOpen(true);
        setRetryIndex(resp.label_index);
        setRetryTotal(resp.label_total);
      }
    } catch (error) {
      console.log(error);
      props.showSnack(
        true,
        "An undetermined error occurred.  If this reoccurs please reach out for assistance.",
        "error"
      );
      props.handlePrintLabelFormOpen(true);
      props.hideBackdrop();
    }
  };

  const handleRetrySubmit = async (event) => {
    event.preventDefault();
    console.log(formValues);

    //complete any field validation in reality this case shouldn't occur
    if (
      formValues.printer == "" ||
      formValues.zplTemplate == "" ||
      retryIndex == null ||
      retryTotal == null
    ) {
      props.showSnack(
        true,
        "Unable to retry, please cancel and resubmit",
        "error"
      );
      return;
    }

    props.handlePrintLabelFormOpen(false);
    setRetryOpen(false);
    props.showBackdrop();

    let header_list = [];
    for (let i = 0; i < props.grid[0].length; i++) {
      header_list.push(props.grid[0][i]["value"] + ":");
    }
    let field_list = [];
    //extract strain batch ids from selected indexes
    for (let i = retryIndex; i < props.grid.length; i++) {
      let rowContainsData = false;
      let row_list = [];
      for (let j = 0; j < props.grid[i].length; j++) {
        if (props.grid[i][j]["value"] != "") {
          rowContainsData = true;
        }
        row_list.push(props.grid[i][j]["value"]);
      }
      if (rowContainsData) {
        field_list.push(row_list);
      }
    }
    const myInit = {
      headers: { Authorization: false },
      body: {
        headers: header_list.slice(1, header_list.length),
        fields: field_list,
        submittedBy: props.userEmail,
        printerId: formValues.printer,
        zplTemplateId: formValues.zplTemplate,
        notes: formValues.notes,
      },
    };
    console.log(myInit);

    try {
      const resp = await API.post("Print", "/newprintjob", myInit);
      console.log("**PRINT RESPONSE**");
      console.log(resp);
      if (resp.job_status == "error") {
        props.showSnack(true, resp.message, "error");
        props.hideBackdrop();
        setRetryOpen(true);
      } else if (resp.job_status == "complete") {
        props.showSnack(true, resp.message, "success");
        setRetryOpen(false);
        props.hideBackdrop();
      } else if (resp.job_status == "partial") {
        props.showSnack(
          true,
          "Error encountered after printing " +
            resp.label_index +
            " labels:\n" +
            resp.message,
          "warning"
        );
        //props.handlePrintLabelFormOpen(false);
        props.hideBackdrop();
        setRetryOpen(true);
        setRetryIndex(resp.label_index);
      }
    } catch (error) {
      console.log(error);
      props.showSnack(
        true,
        "An undetermined error occurred.  If this reoccurs please reach out for assistance.",
        "error"
      );
      props.handlePrintLabelFormOpen(true);
      props.hideBackdrop();
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => {
          props.handlePrintLabelFormOpen(false);
        }}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Print Labels</DialogTitle>
        <DialogContent className="dialogContent">
          <FormControl className="dialogItem" variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">
              Printer
            </InputLabel>
            <Select
              labelId="printer-label"
              id="printer"
              name={"printer"}
              value={formValues.printer}
              onChange={handleInputChange}
              label="Printer"
              helpertext="Select the Zebra label printer you would like to print the labels."
            >
              {props.printers.map((pr, prIndex) => {
                return (
                  <MenuItem
                    key={pr.id}
                    value={pr.id}
                    inputStyle={{ width: "100%", align: "left" }}
                  >
                    {pr.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <span>&nbsp; </span>
          <FormControl className="dialogItem" variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">
              Label Template
            </InputLabel>
            <Select
              labelId="zplTemplate-label"
              id="zplTemplate"
              name={"zplTemplate"}
              value={formValues.zplTemplate}
              onChange={handleInputChange}
              label="Label Template"
              helpertext="Select Label template used when printing labels."
            >
              {props.zplTemplates.map((zpl, zplIndex) => {
                return (
                  <MenuItem key={zpl.id} value={zpl.id}>
                    {zpl.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <span>&nbsp; </span>
          <TextField
            id="outlined-multiline-static"
            label="Notes"
            multiline
            name={"notes"}
            rows={2}
            variant="outlined"
            value={formValues.notes}
            onChange={handleInputChange}
            helpertext="Add any notes to the print job for future reference."
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.handlePrintLabelFormOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Print
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={retryOpen}
        onClose={() => {
          setRetryOpen(false);
        }}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          Retry printing labels starting from {retryIndex} to {retryTotal}?
        </DialogTitle>
        <DialogContent className="dialogContent">
          <FormControl className="dialogItem" variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">
              Printer
            </InputLabel>
            <Select
              labelId="printer-label"
              id="printer"
              name={"printer"}
              value={formValues.printer}
              onChange={handleInputChange}
              label="Printer"
              helpertext="Select the Zebra label printer you would like to print the labels."
            >
              {props.printers.map((pr, prIndex) => {
                return (
                  <MenuItem
                    key={pr.id}
                    value={pr.id}
                    inputStyle={{ width: "100%", align: "left" }}
                  >
                    {pr.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <span>&nbsp; </span>
          <FormControl className="dialogItem" variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">
              Label Template
            </InputLabel>
            <Select
              labelId="zplTemplate-label"
              id="zplTemplate"
              name={"zplTemplate"}
              value={formValues.zplTemplate}
              onChange={handleInputChange}
              label="Label Template"
              helpertext="Select Label template used when printing labels."
            >
              {props.zplTemplates.map((zpl, zplIndex) => {
                return (
                  <MenuItem key={zpl.id} value={zpl.id}>
                    {zpl.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRetryOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleRetrySubmit} color="primary">
            Print Remaining Labels
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

class ZebraDirectLabelPrinter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      grid: [
        [
          { value: "Barcode", className: "headerCell", readOnly: true },
          { value: "(click to edit)", className: "headerCell" },
          { value: "Tag 2", className: "headerCell" },
          { value: "Tag 3", className: "headerCell" },
          { value: "Tag 4", className: "headerCell" },
          { value: "Tag 5", className: "headerCell" },
        ],
        [
          { value: "(edit barcode value)" },
          { value: "(edit value)" },
          { value: "500ml" },
          { value: "Algae" },
          { value: "Cyanobacteria" },
          { value: "Science" },
        ],
      ],
      showBackdrop: false,
      showSignOutModal: false,
      printDisabled: false,
      snackOpen: false,
      snackSuccess: "success",
      snackMessage: "",
      printLabelFormOpen: false,
      printers: [],
      zplTemplates: [],
      printersAndTemplatesListUnpopulated: true,
    };
  }

  refreshPrintersAndTemplatesList = async () => {
    console.log("refreshTemplateList");
    const myInit = {
      headers: { Authorization: false },
    };
    console.log(myInit);
    const resp = await API.get("Print", "/getprinterszpltemplates", myInit);
    console.log("*** get printers and zpl templates ***");
    console.log(resp);

    this.setState({
      printers: resp["printers"],
      zplTemplates: resp["zplTemplates"],
    });
  };

  componentDidMount = async () => {
    if (this.state.printersAndTemplatesListUnpopulated) {
      await this.refreshPrintersAndTemplatesList();
    }
  };

  //openPrintDialog = async () => {};

  handleNewRow = () => {
    const newRow = [
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
    ];
    let newGrid = this.state.grid;
    newGrid.push(newRow);
    this.setState({ grid: newGrid });
  };

  handleRemoveRow = () => {
    if (this.state.grid.length > 2) {
      this.setState({
        grid: this.state.grid.slice(0, this.state.grid.length - 1),
      });
    }
  };

  /* Sign Out logic */
  handleSignOutShow = () => {
    this.setState({ showSignOutModal: true });
  };

  handleSignOutCancel = () => {
    this.setState({ showSignOutModal: false });
  };

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
  };

  showSnack = async (open, message, success) => {
    this.setState({
      snackOpen: open,
      snackMessage: message,
      snackSuccess: success,
    });
  };

  showBackdrop = () => {
    this.setState({ showBackdrop: true });
  };
  hideBackdrop = () => {
    this.setState({ showBackdrop: false });
  };

  handlePrintLabelFormOpen = (open) => {
    console.log("handlePrintLabelFormOpen");
    this.setState({ printLabelFormOpen: open });
  };

  render() {
    const gridValues = this.state.grid.slice(1);

    return (
      <div className={"wrapper"}>
        <h1 className={"title"}>New Label Printer (beta)</h1>
        <h3 className={"title"}>
          Create labels and print below. This tool is in "beta" because it is
          new, please reach out to jmarshall@lumen.bio for new features or
          issues.
        </h3>
        <div id={"center"}>
          <div id={"printGrid"}>
            <div id={"buttonGrid"}>
              <IconButton id={"newRowButton"} onClick={this.handleNewRow}>
                <AddBox size={"large"} />
              </IconButton>
              <IconButton id={"removeRowButton"} onClick={this.handleRemoveRow}>
                <DeleteIcon size={"large"} />
              </IconButton>
              <IconButton
                id={"printButton"}
                onClick={() => {
                  this.handlePrintLabelFormOpen(true);
                }}
              >
                <PrintIcon size={"large"} disabled={this.state.printDisabled} />
              </IconButton>
            </div>
            <div id={"dataSheetGrid"}>
              <ReactDataSheet
                id={"datasheet"}
                data={this.state.grid}
                valueRenderer={(cell) => cell.value}
                onCellsChanged={(changes, additions) => {
                  let grid = this.state.grid.map((row) => [...row]);
                  changes.forEach(({ cell, row, col, value }) => {
                    grid[row][col] = { ...grid[row][col], value };
                  });

                  //add new rows if cp/paste is longer than existing grid
                  if (additions) {
                    let newRows = {};
                    for (let i = 0; i < additions.length; i++) {
                      if (newRows.hasOwnProperty(additions[i]["row"])) {
                        if (
                          additions[i]["col"] <
                          newRows[additions[i]["row"]].length
                        ) {
                          newRows[additions[i]["row"]][additions[i]["col"]][
                            "value"
                          ] = additions[i]["value"];
                        }
                      } else {
                        let newRow = [
                          { value: "" },
                          { value: "" },
                          { value: "" },
                          { value: "" },
                          { value: "" },
                          { value: "" },
                        ];
                        if (additions[i]["col"] < newRow.length) {
                          newRow[additions[i]["col"]]["value"] =
                            additions[i]["value"];
                          newRows[additions[i]["row"]] = newRow;
                        }
                      }
                    }
                    for (const [key, value] of Object.entries(newRows)) {
                      grid.push(value);
                    }
                  }
                  this.setState({ grid: grid });
                }}
              />
            </div>
          </div>
          <div id={"exampleGrid"}>
            <h3>Generic Template Examples (approximate fit, not to scale):</h3>
            {gridValues.map((gridValue) => (
              <div>
                <div className={"sampleLabel"}>
                  <img
                    src={sampleBarcode}
                    alt="sampleBarcode"
                    width="100em"
                    height="100em"
                  />
                  <div>
                    <b>{this.state.grid[0][1]["value"]}:</b>{" "}
                    {gridValue[1]["value"]}
                    <br />
                    <b>{this.state.grid[0][2]["value"]}:</b>{" "}
                    {gridValue[2]["value"]}
                    <br />
                    <b>{this.state.grid[0][3]["value"]}:</b>{" "}
                    {gridValue[3]["value"]}
                    <br />
                    <b>{this.state.grid[0][4]["value"]}:</b>{" "}
                    {gridValue[4]["value"]}
                    <br />
                    <b>{this.state.grid[0][5]["value"]}:</b>{" "}
                    {gridValue[5]["value"]}
                  </div>
                </div>
                <br />
              </div>
            ))}
          </div>
          <PrintLabelForm
            open={this.state.printLabelFormOpen}
            handlePrintLabelFormOpen={this.handlePrintLabelFormOpen}
            grid={this.state.grid}
            printers={this.state.printers}
            zplTemplates={this.state.zplTemplates}
            userEmail={this.props.userEmail}
            showSnack={this.showSnack}
            showBackdrop={this.showBackdrop}
            hideBackdrop={this.hideBackdrop}
          />
        </div>
        <div className={"print-footer"}>
          <Portal>
            <Snackbar
              open={this.state.snackOpen}
              onClose={this.handleSnackClose}
              autoHideDuration={30000}
            >
              <Alert
                onClose={this.handleSnackClose}
                severity={this.state.snackSuccess}
              >
                {this.state.snackMessage}
              </Alert>
            </Snackbar>
          </Portal>
        </div>
        <Backdrop open={this.state.showBackdrop}>
          <CircularProgress color="primary" />
        </Backdrop>
      </div>
    );
  }
}

export default ZebraDirectLabelPrinter;
