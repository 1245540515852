import React from 'react';
import MUIDataTable from "mui-datatables";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import moment from 'moment';
import Amplify, { API, Auth } from 'aws-amplify';

import './labFileArchiveComponent.css';


const startVal = [
    {
        name: "Please wait a couple minutes as we load archive data..."
    },
];

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function roundTo(n, digits) {
    let negative = false;
    if (digits === undefined) {
        digits = 0;
    }
    if( n < 0) {
        negative = true;
        n = n * -1;
    }
    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    n = (Math.round(n) / multiplicator).toFixed(2);
    if( negative ) {
        n = (n * -1).toFixed(2);
    }
    return n;
}

function convertTimestampToDateString(unixTimestamp) {
    let myDate = new Date(unixTimestamp * 1000);
    // Will display date in MM/DD/YY
    return  (myDate.getMonth()+1) + '/' + myDate.getDate() + '/' + (myDate.getYear()-100)
}

const footerHeight = 100;

const column_desc_enum = {
    "string":0,
    "link_string_number":1,
    "string_number":2,
    "string_date":3,
    "link_string_date":4,
    "link_string":5,
    "link_number":6,
    "number":7
}
const originDate = new Date(0);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class LabFileArchiveComponent extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            tableBodyHeight: (window.innerHeight
                || document.documentElement.clientHeight
                || document.body.clientHeight)  - 120,
            rows: startVal,
            rowsSelected: [],
            //OneDrive Pipeline
            nameList: "",
            showOneDriveModal: false,
            saveOneDriveButtonVariant: "primary",
            saveOneDriveButtonLabel: "Copy File(s) to OneDrive",
            saveOneDriveButtonDisabled: true,
            snackOpen: false,
            snackSuccess: 'success',
            snackMessage: '',
            //sign out vars
            showSignOutModal: false,
            //showBackdrop until data loads
            showBackdrop: false,
            rowsPerPage: 25
        };
        this.handleResize.bind(this);
    }

    handleResize = () => {
        this.setState({tableBodyHeight: (window.innerHeight
                || document.documentElement.clientHeight
                || document.body.clientHeight)  - 120});
    }


    refreshData = async () => {
        let fileList = [];
        let lastKey = "";
        let respFileList = []
        let myInit = {
            headers:{Authorization: false},
        };
        do {
            console.log(myInit);
            const respPayload = await API.get('O365', '/listArchive', myInit);
            respFileList = respPayload.fileList;
            console.log(respFileList)
            fileList = fileList.concat(respFileList)
            if (respFileList.length > 0){
                myInit.queryStringParameters = {lastKey: respFileList[respFileList.length-1][0]}
            }
        } while (respFileList.length > 0)

        for (let x = 0; x < fileList.length; x++){
            fileList[x][5] = convertTimestampToDateString(fileList[x][5])
        }

        console.log("*");
        console.log(fileList);
        this.setState({rows: fileList});
    }

    componentDidMount = async () => {
        //start data sync interval
        window.addEventListener('resize', this.handleResize);

        //set user info in state
        const user = await Auth.currentAuthenticatedUser();
        console.log(user.attributes);
        this.setState({userEmail: user.attributes.email});
        this.refreshData();
    }
    componentWillUnmount = async () => {
        console.log('componentWillUnmount');
        window.removeEventListener('resize', this.handleResize);
    }

/* OneDrive pipeline processing */
    handleOneDriveCancel = () => {
        this.setState({showOneDriveModal: false});
    }
    handleOneDriveSave = async () => {
        this.setState({
            saveOneDriveButtonVariant: "info",
            saveOneDriveButtonLabel: "Copying Files..",
            saveOneDriveButtonDisabled: true
        })
        let nameList = [];
        //extract strain batch ids from selected indexes
        for (let x = 0; x < this.state.rowsSelected.length; x++){
            console.log(this.state.rows[this.state.rowsSelected[x]]);
            if (this.state.rows[this.state.rowsSelected[x]][0]) {
                
                const myInit = {
                    headers:{Authorization: false},
                    body: {
                        username: this.props.userEmail,
                        key: this.state.rows[this.state.rowsSelected[x]][0]
                    },
                };
                console.log(myInit)
                try{
                    const resp = await Amplify.API.post('O365', '/pushKeyToOneDrive', myInit);
                    console.log(resp)
                    this.setState({
                        snackOpen: true,
                        snackSuccess: 'success',
                        snackMessage: this.state.rows[this.state.rowsSelected[x]][1] + ' successfully copied to OneDrive',
                        })
                } catch {
                        this.setState({
                            snackOpen: true,
                            snackSuccess: 'error',
                            snackMessage: 'One or more files could not be copied to OneDrive.  Please reach out for assistance.',
                        })
                }
            }
        }
        this.setState({
            showOneDriveModal: false,
            rowsSelected: [],
            saveOneDriveButtonVariant: "primary",
            saveOneDriveButtonLabel: "Copy File(s) to OneDrive",
            saveOneDriveButtonDisabled: true            
        })

    }

    handleOneDriveShow = () => {
        if (this.state.rowsSelected.length == 0){
            alert("Please select files for copying using the boxes on the left.")
            return
        }
        if (this.state.rowsSelected.length > 10){
            alert("Too many files selected.  Please copy only 10 at a time.")
            return
        }

        let nameList = "";
        for (let x = 0; x < this.state.rowsSelected.length; x++){
            console.log(this.state.rows[this.state.rowsSelected[x]]);
            if (this.state.rows[this.state.rowsSelected[x]][6] == 0) {
                alert("One selected file has a size of zero and cannot be transferred")
                return
            }
            if (this.state.rows[this.state.rowsSelected[x]][1]) {
                nameList = nameList + this.state.rows[this.state.rowsSelected[x]][1] + " ";
            }
        }
        console.log(nameList)
        this.setState({showOneDriveModal: true, saveOneDriveButtonDisabled:false, "nameList":nameList});
    }

    //return all values in column sorted desc with dupes removed
    getFilterOptions = (column) => {
        let columnName = column['name'];
        let mySet = new Set();
        let data = this.state.rows;
        for (let i = 0;i<data.length;i++){
            if (data[i][columnName]){
                if (data[i][columnName]['value']){
                    mySet.add(data[i][columnName]['value']);
                }
            }
        }
        return Array.from(mySet).sort();
    }
    linkBodyRender = (value, tableMeta, updateValue) => {
        return (
            <a href={ value ? value['url']:null }  target="_blank" rel="noopener noreferrer" >{ value ? value['value']:null }</a>
        )
    }
    customFilterLogic = (link_obj, filters, row) => {
        if (filters.length) {
            return !filters.includes(link_obj['value']);
        } else {
            return false;
        }
    }
    customFilterDisplay = (filterList, onChange, index, column) => {
        const optionValues = this.getFilterOptions(column);
        return (
            <div>
                <InputLabel htmlFor={column.name}>{column.label}</InputLabel>
                <Select
                    fullWidth
                    value={filterList[index].length > 0 ? filterList[index].toString() : "All"}
                    name={column.name}
                    onChange={ event => {
                        filterList[index] = event.target.value != "All" ? [event.target.value]:[];
                        onChange(filterList[index], index, column);
                    }}
                    input={<Input name={column.name} id={column.name} />}
                >
                    <MenuItem value={"All"} key={"All"} >
                        All
                    </MenuItem>
                    {optionValues.map((filterValue) => (
                        <MenuItem value={filterValue} key={filterValue} >
                            {filterValue}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        )}

    handleSnackClose = () => {
        this.setState({snackOpen: false});
    };

    render() {
        const normColumnOptions = { filter: true, sort: true };
        const normColumnOptionsDisplayFalse = { display: false, filter: true, sort: true };
        const linkColumnOptions = {
            filter: true,
            filterType: 'custom',
            filterOptions: {
                logic: this.customFilterLogic,
                display: this.customFilterDisplay,
            },
            sort: true,
            customBodyRender: this.linkBodyRender
        }
        const linkColumnOptionsFilterOff = {
            filter: false,
            sort: true,
            customBodyRender: this.linkBodyRender
        }

        let columns;

        columns = [
                { name: "key", label: "AWS Key", options: normColumnOptionsDisplayFalse},
                { name: "name", label: "Name", options: normColumnOptions},
                { name: "source", label: "Name", options: normColumnOptions},
                { name: "user", label: "Uploaded By", options: normColumnOptions},
                { name: "extension", label: "Type",  options: normColumnOptions},
                { name: "timestamp", label: "Created Date",  options: normColumnOptions},
                { name: "size", label: "Size",  options: normColumnOptions},
            ];

        let options = {
            print: false,
            rowsPerPage: this.state.rowsPerPage,
            rowsPerPageOptions: [5, 25, 50, 1000],
            responsive: 'standard',
            fixedHeader: true,
            fixedSelectColumn: true,
            tableBodyHeight: this.state.tableBodyHeight,
            rowsSelected: this.state.rowsSelected,
            onChangeRowsPerPage: (numberOfRows) => {
                console.log(numberOfRows);
                this.setState({ rowsPerPage: numberOfRows});
            },
            onRowSelectionChange: (rowsSelected, allRows) => {
                console.log(rowsSelected, allRows);
                this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
            },
            customSearch: (searchQuery, currentRow, columns) => {
                let isFound = false;
                currentRow.forEach(col => {
                    if (col) {
                        if (col.toString().indexOf(searchQuery) >= 0) {
                            isFound = true;
                        }
                    }
                });
                return isFound;
            },
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                return (<>
                    <Button onClick={this.handleOneDriveShow} variant="contained" color="primary" size="sm">
                        Copy to OneDrive
                    </Button> { }
                    <Dialog
                        open={this.state.showOneDriveModal}
                        onClose={this.handleOneDriveCancel}
                        fullWidth={true}
                    >
                        <DialogTitle id="form-dialog-title">Copy to OneDrive</DialogTitle>
                        <DialogContent className="dialogContent">
                            <p>Files(s) { this.state.nameList } will be copied to your personal OneDrive.</p>

                        </DialogContent>
                        <DialogActions>
                            <Button variant="secondary"  onClick={this.handleOneDriveCancel}>
                                Close
                            </Button>
                            <Button variant={this.state.saveOneDriveButtonVariant}
                                    onClick={this.handleOneDriveSave}
                                    disabled = {this.state.saveOneDriveButtonDisabled || this.state.jobNameChangeError}>
                                {this.state.saveOneDriveButtonLabel}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    { }
                </>)
            },
            downloadOptions: {filename: 'basespace.csv',
                separator: ',',
                filterOptions:{ useDisplayedColumnsOnly: false,
                    useDisplayedRowsOnly: false}},
            onDownload: (buildHead, buildBody, columns, data) => {
                console.log('building csv');
                console.log(data);
                let csvData = [];
                for (let i = 0;i<data.length;i++){
                    let csvRowData = [];
                    for (let j = 0; j<data[i]['data'].length;j++){
                        //console.log(j)
                        if (!data[i]['data'][j]){
                            csvRowData.push('');
                        }
                        else if (typeof(data[i]['data'][j]) == "object"){
                            if (data[i]['data'][j]['value']) {
                                csvRowData.push(data[i]['data'][j]['value']);
                            }
                            else {
                                csvRowData.push('');
                            }
                        }
                        else {
                            csvRowData.push(data[i]['data'][j]);
                        }
                    }
                    csvData.push( {index: data[i]['index'], data: csvRowData} );
                }
                console.log(csvData);
                return buildHead(columns) + buildBody(csvData);
            },
        };
        if (this.props.selected) {
            //strain eng columns
            options['customSort'] = (data, colIndex, order) => {
                const columns_desc = [
                    /*key*/ column_desc_enum.string,
                    /*name*/ column_desc_enum.string,
                    /*source*/ column_desc_enum.string,
                    /*user*/ column_desc_enum.string,
                    /*extension*/ column_desc_enum.string,
                    /*timestamp*/ column_desc_enum.string_date,
                    /*size*/ column_desc_enum.number
                ];
                console.log(data)
                //console.log(colIndex);
                //console.log(order);
                const strPlaceholder = "AAAAAAAAAAAAAAAA";
                return data.sort((a, b) => {
                    let left;
                    let right;

                    switch(columns_desc[colIndex]){
                        case column_desc_enum.string:
                            left = a.data[colIndex] ? a.data[colIndex].toUpperCase() : strPlaceholder;
                            right = b.data[colIndex] ? b.data[colIndex].toUpperCase() : strPlaceholder;
                            break;
                        case column_desc_enum.link_string_number:
                            console.log("left" + a.data[colIndex]['value'])
                            console.log("right" + b.data[colIndex]['value'])
                            left = a.data[colIndex]['value'] ? parseFloat(/\d+/g.exec(a.data[colIndex]['value'])[0]) : -999999;
                            right = b.data[colIndex]['value'] ? parseFloat(/\d+/g.exec(b.data[colIndex]['value'])[0]) : -999999;
                            break;
                        case column_desc_enum.string_number:
                            left = a.data[colIndex] ? parseFloat(/\d+/g.exec(a.data[colIndex])[0]) : -999999;
                            right = b.data[colIndex] ? parseFloat(/\d+/g.exec(b.data[colIndex])[0]) : -999999;
                            break;
                        case column_desc_enum.string_date:
                            left = (moment(a.data[colIndex], "MM/DD/YY").isValid()) ?
                                moment(a.data[colIndex], "MM/DD/YY").toDate() :
                                originDate;
                            right = (moment(b.data[colIndex], "MM/DD/YY").isValid()) ?
                                moment(b.data[colIndex], "MM/DD/YY").toDate() :
                                originDate;
                            break;
                        case column_desc_enum.link_string_date:
                            left = (moment(a.data[colIndex]['value'], "MM/DD/YY").isValid()) ?
                                moment(a.data[colIndex]['value'], "MM/DD/YY").toDate() :
                                originDate;
                            right = (moment(b.data[colIndex]['value'], "MM/DD/YY").isValid()) ?
                                moment(b.data[colIndex]['value'], "MM/DD/YY").toDate() :
                                originDate;
                            break;
                        case column_desc_enum.link_string:
                            left = a.data[colIndex]['value'] ? a.data[colIndex]['value'].toUpperCase() : -999999;
                            right = b.data[colIndex]['value'] ? b.data[colIndex]['value'].toUpperCase() : -999999;
                            break;
                        case column_desc_enum.link_number:
                            left = a.data[colIndex]['value'] ? parseFloat(a.data[colIndex]['value']) : -999999;
                            right = b.data[colIndex]['value'] ? parseFloat(b.data[colIndex]['value']) : -999999;
                            break;
                        case column_desc_enum.number:
                            left = a.data[colIndex] ? parseFloat(a.data[colIndex]) : -999999;
                            right = b.data[colIndex] ? parseFloat(b.data[colIndex]) : -999999;
                            break;
                    }
                    return (left == right ? 0 : left > right ? 1 : -1) * (order === 'desc' ? 1 : -1);
                });
            }
        } else {
            options['customSort'] = (data, colIndex, order) => {
                const columns_desc = [
                    /*key*/ column_desc_enum.string,
                    /*name*/ column_desc_enum.string,
                    /*source*/ column_desc_enum.string,
                    /*user*/ column_desc_enum.string,
                    /*extension*/ column_desc_enum.string,
                    /*timestamp*/ column_desc_enum.string_date,
                    /*size*/ column_desc_enum.number
                ];
                //console.log(colIndex);
                //console.log(order);
                const strPlaceholder = "AAAAAAAAAAAAAAAA";
                return data.sort((a, b) => {
                    let left;
                    let right;

                    switch (columns_desc[colIndex]) {
                        case column_desc_enum.string:
                            left = a.data[colIndex] ? a.data[colIndex].toUpperCase() : strPlaceholder;
                            right = b.data[colIndex] ? b.data[colIndex].toUpperCase() : strPlaceholder;
                            break;
                        case column_desc_enum.link_string_number:
                            left = a.data[colIndex]['value'] ? parseFloat(/\d+/g.exec(a.data[colIndex]['value'])[0]) : -999999;
                            right = b.data[colIndex]['value'] ? parseFloat(/\d+/g.exec(b.data[colIndex]['value'])[0]) : -999999;
                            break;
                        case column_desc_enum.string_number:
                            left = a.data[colIndex] ? parseFloat(/\d+/g.exec(a.data[colIndex])[0]) : -999999;
                            right = b.data[colIndex] ? parseFloat(/\d+/g.exec(b.data[colIndex])[0]) : -999999;
                            break;
                        case column_desc_enum.string_date:
                            left = (moment(a.data[colIndex], "MM/DD/YY").isValid()) ?
                                moment(a.data[colIndex], "MM/DD/YY").toDate() :
                                originDate;
                            right = (moment(b.data[colIndex], "MM/DD/YY").isValid()) ?
                                moment(b.data[colIndex], "MM/DD/YY").toDate() :
                                originDate;
                            break;
                        case column_desc_enum.link_string_date:
                            left = (moment(a.data[colIndex]['value'], "MM/DD/YY").isValid()) ?
                                moment(a.data[colIndex]['value'], "MM/DD/YY").toDate() :
                                originDate;
                            right = (moment(b.data[colIndex]['value'], "MM/DD/YY").isValid()) ?
                                moment(b.data[colIndex]['value'], "MM/DD/YY").toDate() :
                                originDate;
                            break;
                        case column_desc_enum.link_string:
                            left = a.data[colIndex]['value'] ? a.data[colIndex]['value'].toUpperCase() : strPlaceholder;
                            right = b.data[colIndex]['value'] ? b.data[colIndex]['value'].toUpperCase() : strPlaceholder;
                            break;
                        case column_desc_enum.link_number:
                            left = a.data[colIndex]['value'] ? parseFloat(a.data[colIndex]['value']) : -999999;
                            right = b.data[colIndex]['value'] ? parseFloat(b.data[colIndex]['value']) : -999999;
                            break;
                        case column_desc_enum.number:
                            left = a.data[colIndex] ? parseFloat(a.data[colIndex]) : -999999;
                            right = b.data[colIndex] ? parseFloat(b.data[colIndex]) : -999999;
                            break;
                    }
                    return (left == right ? 0 : left > right ? 1 : -1) * (order === 'desc' ? 1 : -1);
                });
            }
        }


        return (
            <div id={"wrapper"}>
                <div className={"content"}>
                    <MUIDataTable
                        title={this.props.title}
                        data={this.state.rows}
                        columns={columns}
                        options={options}
                    />
                </div>
                <Snackbar open={this.state.snackOpen} onClose={this.handleSnackClose} autoHideDuration={2000}>
                    <Alert onClose={this.handleSnackClose} severity={this.state.snackSuccess} >
                        {this.state.snackMessage}
                    </Alert>
                </Snackbar>
                <Backdrop open={this.state.showBackdrop}>
                    <CircularProgress color="primary" />
                </Backdrop>
            </div>
        );
    }
}

export default LabFileArchiveComponent;
