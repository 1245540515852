import "./App.css";
import awsconfig from "./aws-exports";

import Amplify, { Auth } from "aws-amplify";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import Drawer from "@material-ui/core/Drawer";
import { Code, Print } from "@material-ui/icons";
import LinkIcon from "@material-ui/icons/Link";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import React from "react";

import ZebraDirectLabelPrinter from "./zebraDirectLabelPrinterComponent";
import O365CredentialCapture from "./o365CredentialCaptureComponent";
import LabFileArchive from "./labFileArchiveComponent";
import GrowthRunRegistrar from "./growthRunRegistrar";

Amplify.configure(awsconfig);
Auth.configure(awsconfig);
Amplify.configure({
  API: {
    endpoints: [
      {
        name: "Print",
        endpoint: "https://sckjgpbu08.execute-api.us-west-2.amazonaws.com/Prod",
      },
      {
        name: "riffynForms",
        endpoint: "https://ux5zwii3b1.execute-api.us-west-2.amazonaws.com/Prod",
      },
      {
        name: "O365",
        endpoint: "https://iub00606l8.execute-api.us-west-2.amazonaws.com/Prod",
      },
      {
        name: "growthRunRegistrar",
        endpoint: "https://1wup0of9ik.execute-api.us-west-2.amazonaws.com/Prod",
      },
    ],
  },
});

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

const Welcome = () => (
  <div>
    <h1>
      {" "}
      Welcome to the Lumen Production Toolkit. Please navigate to your tool of
      choice with the menu.{" "}
    </h1>
  </div>
);

function App() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [cognitoUser, setCognitoUser] = React.useState(false);
  const [signOutOpen, setSignOutOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open);
  };

  /* Sign Out logic */
  const toggleSignOut = (open) => (event) => {
    setSignOutOpen(open);
  };

  React.useEffect(() => {
    if (cognitoUser == false) {
      async function getCognitoUser() {
        const currentCognitoUser = await Auth.currentAuthenticatedUser();
        setCognitoUser(currentCognitoUser);
      }
      getCognitoUser();
    }
  }, [cognitoUser]);

  //user.attributes.email,

  return (
    <Router>
      <div className="App">
        <div className="Sidebar">
          <IconButton id={"menuButton"} onClick={toggleDrawer(true)}>
            <MenuIcon size={"large"} />
          </IconButton>
          <Drawer
            anchor={"left"}
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            <h3>&nbsp;&nbsp;Production Tools:</h3>
            <List>
              <Link to={"/new_label_printer"}>
                <ListItem
                  button
                  key={"Label Printer"}
                  onClick={toggleDrawer(false)}
                >
                  <ListItemIcon>
                    <Print />
                  </ListItemIcon>
                  <ListItemText primary={"Label Printer"} />
                </ListItem>
              </Link>
              {/*
              <Link to={"/template_repository"}>
                <ListItem
                  button
                  key={"Riffyn Form Template Repository"}
                  onClick={toggleDrawer(false)}
                >
                  <ListItemIcon>
                    <Code />
                  </ListItemIcon>
                  <ListItemText primary={"Riffyn Form Template Repository"} />
                </ListItem>
              </Link>
              */}
              <Link to={"/lab_instrument_file_setup"}>
                <ListItem
                  button
                  key={"Lab Instrument File Setup"}
                  onClick={toggleDrawer(false)}
                >
                  <ListItemIcon>
                    <LinkIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Lab Instrument File Setup"} />
                </ListItem>
              </Link>
              <Link to={"/lab_instrument_archive"}>
                <ListItem
                  button
                  key={"Lab Instrument Archive"}
                  onClick={toggleDrawer(false)}
                >
                  <ListItemIcon>
                    <UnarchiveIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Lab Instrument Archive"} />
                </ListItem>
              </Link>
              <Link to={"/growth_run_registrar"}>
                <ListItem
                  button
                  key={"Growth Run Registrar"}
                  onClick={toggleDrawer(false)}
                >
                  <ListItemIcon>
                    <AppRegistrationIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Growth Run Registrar"} />
                </ListItem>
              </Link>
            </List>
          </Drawer>
        </div>
        <div className="Main">
          <Switch>
            <Route exact path={"/"}>
              <h1>
                &nbsp;&nbsp;&nbsp;&nbsp;Welcome to the Lumen Production Toolkit.
                Please navigate to your tool of choice with the menu.
              </h1>
            </Route>
            <Route exact path={"/new_label_printer"}>
              <ZebraDirectLabelPrinter
                userEmail={cognitoUser ? cognitoUser.attributes.email : "..."}
              />
            </Route>
            {/*
            <Route exact path={"/template_repository"}>
              <FileRepository
                userEmail={cognitoUser ? cognitoUser.attributes.email : "..."}
              />
            </Route>
              */}
            <Route exact path={"/lab_instrument_file_setup"}>
              <O365CredentialCapture
                userEmail={cognitoUser ? cognitoUser.attributes.email : "..."}
              />
            </Route>
            <Route exact path={"/lab_instrument_archive"}>
                <LabFileArchive
                userEmail={cognitoUser ? cognitoUser.attributes.email : "..."}
                />
              </Route>
            <Route exact path={"/growth_run_registrar"}>
                <GrowthRunRegistrar
                  userEmail={cognitoUser ? cognitoUser.attributes.email : "..."}
                />
              </Route>
          </Switch>
        </div>
        <div className="footer">
          <a className={"footer-a"} onClick={toggleSignOut(true)}>
            {cognitoUser ? cognitoUser.attributes.email : "..."} logged in
          </a>
          <Dialog open={signOutOpen} onClose={toggleSignOut(false)}>
            <AmplifySignOut />
          </Dialog>
        </div>
      </div>
    </Router>
  );
}

export default withAuthenticator(App);
