import React, { useState } from "react";
import {API} from "aws-amplify";
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert from "@material-ui/lab/Alert";
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import Slide from '@mui/material/Slide';

import "./growthRunRegistrar.css";

const prodReactors = [
  { label: '101'},
  { label: '102'},
  { label: '103'},
  { label: '104'},
  { label: '105'},
  { label: '106'},
  { label: '107'},
  { label: '108'},
  { label: '109'},
  { label: '110'},
  { label: '111'},
  { label: '112'}]

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function formattedLocalTime(datetime_iso) {
  if (datetime_iso == " "){
    return " ";
  }
  const datetime = new Date(`${datetime_iso}+00:00`);
  return `${datetime.getMonth()+1}-${datetime.getDate()}-${String(datetime.getFullYear()).substring(2,4)} ${datetime.getHours()}:${datetime.getMinutes()}:${datetime.getSeconds()}`;
}

function TimeLogger(props) {
  const [isLoading, setIsLoading] = useState(false);

  const newLogOnClick = async () => {
      setIsLoading(true);
      await props.handleNewLogSubmit(props.runId, props.type)
      setIsLoading(false);
  }

  if (isLoading) {
    return (
        <div>
          <CircularProgress
            size={20}
          />
        </div>
    )
  } else {
    return (
        <div>
          <Tooltip title={"Log Start Time"}>
            <IconButton
                color="primary"
                onClick={newLogOnClick}
            >
              <MoreTimeIcon size={"medium"}/>
            </IconButton>
          </Tooltip>
        </div>
    );
  }
}

class GrowthRunRegistrar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      strainBatchList: [],
      seedRunList: [],
      runList: [{prod_growth_run_name: "Loading growth runs", start_timestamp: " ", end_timestamp: " "}],
      runListUnpopulated: true,
      showBackdrop: false,
      showInfoDialog: false,
      showUploadDialog: false,
      snackOpen: false,
      snackSuccess: "success",
      snackMessage: "",
      infoDialogTemplate: null,
      showMergeDialog: false,
      mergeKeyValue: null,
      experimentIdValue: "",
      showNewRunDialog: false,
      newReactorSubmitDisabled: true,
      reactorValue: "",
      reactorRunNumberValue: null,
      strainBatchValue: "",
      seedRunValue: "",
      showNewLogDialog: false,
    };
  }

  refreshRunList = async () => {
    console.log("refreshRunList");
    const myInit = {
      headers: { Authorization: false },
    };
    console.log(myInit);
    const resp = await API.get("growthRunRegistrar", "/retrieveProdBioreactorRuns", myInit);

    const strainBatchAuto = resp.strain_batches.map((row) => {
      return {label: row.name,
        sbId: row.id,
        batchName: row.batch_name,
        cultureType: row.culture_type}
    })
    const seedRunAuto = resp.runs.map((row) => {
      return {label: row.benchling_biomass_run_name,
        srId: row.run_id,
        sbId: row.strain_batch_id,
        sbName: row.strain_batch_name}
    })

    console.log(resp.runs);
    this.setState({
      strainBatchList: strainBatchAuto,
      seedRunList: seedRunAuto,
      runList: resp.runs,
      runListUnpopulated: false,
    });
  };

  componentDidMount = async () => {
    if (this.state.runListUnpopulated) {
      await this.refreshRunList();
    }
  };
  handleNewRun = () => {
    console.log("handleNewRun");
    this.setState({
      showNewRunDialog: true
    });
  };
  handleNewRunCancel = () => {
    console.log("handleNewRunCancel");
    this.setState({
      showNewRunDialog: false
    });
  };

  handleNewRunSubmit = async () => {
    this.setState({
      showBackdrop: true,
      showNewRunDialog: false,
    });
      const myInit = {
        headers: { Authorization: false },
        body: {
          reactorRunNumber: this.state.reactorRunNumberValue,
          strainBatchId: (!this.state.seedRunValue || this.state.seedRunValue == "") ? this.state.strainBatchValue['sbId'] : this.state.seedRunValue['sbId'],
          strainBatchName: (!this.state.seedRunValue || this.state.seedRunValue == "") ? this.state.strainBatchValue['label'] : this.state.seedRunValue['sbName'],
          reactor: this.state.reactorValue['label'],
          seedRunId: (this.state.seedRunValue && this.state.seedRunValue != "") ? this.state.seedRunValue['srId'] : null
        },
      };
      console.log("handleNewRunSubmit");
      console.log(myInit);
      const resp = await API.post("growthRunRegistrar", "/createBioreactorRun", myInit);
      console.log(resp)

        if (resp.run_id) {
        const newRun = {
          run_id: resp.run_id,
          benchling_biomass_run_name: resp.benchling_biomass_run_name,
          prod_growth_run_name: resp.prod_growth_run_name,
          strain_batch_id: this.state.strainBatchValue['sbId'],
          strain_batch_name: this.state.strainBatchValue['label'],
          reactor: this.state.reactorValue['label'],
          start_timestamp: null,
          end_timestamp: null
        }
        let newRunList = this.state.runList;
        newRunList.unshift(newRun);
        console.log(this.state.runList);

        this.setState({
          runList:newRunList,
          showBackdrop: false,
          snackOpen: true,
          snackMessage: "New Run Submitted",
          snackSuccess: "success",
        });
      } else {
          this.setState({
          showBackdrop: false,
          snackOpen: true,
          snackMessage: "Benchling Error: " + resp.message,
          snackSuccess: "error",
        });

      }
      /*
    } else {
      this.setState({
        showBackdrop: false,
        snackOpen: true,
        snackMessage: "Benchling error, please reach out for assistance.",
        snackSuccess: "error",
      });
    }*/
  };

  handleNewLogSubmit = async (id, type) => {

      const myInit = {
        headers: { Authorization: false },
        body: {
          runId: id,
          processStep: type,
        },
      };
      console.log("handleNewLogSubmit");
      console.log(myInit);
      const resp = await API.post("growthRunRegistrar", "/createProcessLogResult", myInit);

      if (resp.iso_timestamp) {
        console.log(resp.iso_timestamp)
        const time = resp.iso_timestamp;
        let data = this.state.runList;
        for (let x = 0; x < data.length; x++) {
          if (data[x]['run_id'] == id) {
            if (type == "Start") {
              data[x]['start_timestamp'] = time;
            } else if (type == "End") {
              data[x]['end_timestamp'] = time;
            }
          }
        }
        this.setState({
          runList: data,
        });
      } else {
          this.setState({
          showBackdrop: false,
          snackOpen: true,
          snackMessage: "Benchling Error: " + resp.message,
          snackSuccess: "error",
        });
      }
  };

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
  };

  render() {
    return (
        <>
      <div className={"wrapper"}>
        <h1 className={"title"}>Growth Run Registrar</h1>
        <h3 className={"title"}>
          Register growth runs in Benchling to enable Apex logging and carbon fixation calculations
        </h3>
        <div id={"center"}>
          <div id={"Grid"}>
            <div id={"buttonGrid"}>
              <Tooltip title={"Create new growth run"}>
                <Button color="primary"
                        variant="contained"
                        startIcon={<CreateIcon size={"medium"} />}
                        onClick={this.handleNewRun}
                        disabled={this.state.runListUnpopulated}>
                  New Growth Run
                </Button>
              </Tooltip>
            </div>
            <div id={"fileList"}>
              <br />
                    <>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                            <TableCell>Growth Run Name</TableCell>
                            <TableCell align="right">Seed Run Name</TableCell>
                            <TableCell align="right">Strain Batch Name</TableCell>
                            <TableCell align="right">Reactor</TableCell>
                            <TableCell align="right">Start Time</TableCell>
                            <TableCell align="right">End Time</TableCell>
                              <TableCell align="right"> </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.runList.map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {row.prod_growth_run_name}
                              </TableCell>
                              <TableCell align="right">{row.seed_run_name}</TableCell>
                              <TableCell align="right">{row.strain_batch_name}</TableCell>
                              <TableCell align="right">{row.reactor}</TableCell>
                              <TableCell align="right">{row.start_timestamp ? formattedLocalTime(row.start_timestamp) : (
                                  <TimeLogger
                                  handleNewLogSubmit={this.handleNewLogSubmit}
                                  runId={row.run_id}
                                  type="Start"
                                />
                              )}
                              </TableCell>
                              <TableCell align="right">{row.end_timestamp ? formattedLocalTime(row.end_timestamp) : (
                                <TimeLogger
                                  handleNewLogSubmit={this.handleNewLogSubmit}
                                  runId={row.run_id}
                                  type="End"
                                />
                              )}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        </Table>
                      </TableContainer>
                    </>
            </div>
          </div>
          <Dialog
            open={this.state.showNewRunDialog}
            onClose={this.handleNewRunCancel}
            aria-labelledby="form-dialog-title"
            fullScreen
            TransitionComponent={Transition}
          >
            <DialogTitle id="form-dialog-title">Create New Run</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter the below fields to create a new Growth Run in the system.
              </DialogContentText>
              <span id="NewRunSpan">
              <Autocomplete
                disablePortal
                id="reactorName"
                options={prodReactors}
                sx={{ width: 100 }}
                renderInput={(params) => <TextField {...params} label="Reactor" />}
                onChange={(event, newValue) => {
                  let newState = {};
                  newState.reactorValue = newValue;
                  if (this.state.reactorRunNumberValue > 0 && this.state.strainBatchValue && newValue) {
                    newState.newReactorSubmitDisabled = false;
                  } else {
                    newState.newReactorSubmitDisabled = true;
                  }
                  this.setState(newState);
                }}
              />
                {"  "}
                <TextField
                id="name"
                label="Reactor Run Number"
                value={this.state.reactorRunNumberValue}
                onChange={(event) => {
                  console.log(event.target.value)
                  let newState = {};
                  let number = parseInt(event.target.value);
                  if (!number){
                    number = null;
                  }
                  if (number > 999){
                    return;
                  } else {
                    newState.reactorRunNumberValue = number;
                  }
                  if (number > 0 && this.state.strainBatchValue && this.state.reactorValue) {
                    newState.newReactorSubmitDisabled = false;
                  } else {
                    newState.newReactorSubmitDisabled = true;
                  }
                  this.setState(newState);
                }}
                sx={{ width: 100 }}
                type={"number"}
              />
                {"  "}
              <Autocomplete
                disablePortal
                id="seedRun"
                options={this.state.seedRunList}
                getOptionLabel={(option) => option.label}
                sx={{ width: 600 }}
                renderInput={(params) => <TextField {...params} label="SeedRun" />}
                onChange={(event, newValue) => {
                  let newState = {};
                  console.log(newValue)
                  newState.seedRunValue = newValue;
                  if (this.state.reactorRunNumberValue > 0 && newValue && this.state.reactorValue) {
                    newState.newReactorSubmitDisabled = false;
                  } else {
                    newState.newReactorSubmitDisabled = true;
                  }
                  this.setState(newState);
                }}
              />
              <Autocomplete
                disablePortal
                id="strainBatch"
                options={this.state.strainBatchList}
                getOptionLabel={(option) => option.label + " - " + (option.batchName ? option.batchName : "") + " - " + (option.cultureType ? option.cultureType : "")}
                sx={{ width: 600 }}
                renderInput={(params) => <TextField {...params} label="StrainBatch" />}
                onChange={(event, newValue) => {
                  let newState = {};
                  console.log(newValue)
                  newState.strainBatchValue = newValue;
                  if (this.state.reactorRunNumberValue > 0 && newValue && this.state.reactorValue) {
                    newState.newReactorSubmitDisabled = false;
                  } else {
                    newState.newReactorSubmitDisabled = true;
                  }
                  this.setState(newState);
                }}
              />
              </span>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleNewRunCancel} color="primary" size={"large"}>
                Cancel
              </Button>
              <Button onClick={this.handleNewRunSubmit} color="primary" size={"large"} disabled={this.state.newReactorSubmitDisabled}>
                Create Run
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div className={"page-footer"}>
          <Snackbar
            open={this.state.snackOpen}
            onClose={this.handleSnackClose}
            autoHideDuration={5000}
          >
            <Alert
              onClose={this.handleSnackClose}
              severity={this.state.snackSuccess}
            >
              {this.state.snackMessage}
            </Alert>
          </Snackbar>
        </div>
      </div>
          <div className={"backdrop"}>
          <Backdrop open={this.state.showBackdrop}>
            <CircularProgress color="primary" />
          </Backdrop>
          </div>
    </>
    );
  }
}

export default GrowthRunRegistrar;