import React from "react";
import { API } from "aws-amplify";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Portal from "@material-ui/core/Portal";

import "./o365CredentialCaptureComponent.css";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class O365CredentialCapture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validToken: false,
      newTokenButtonDisabled: false,
      showBackdrop: false,
      showSignOutModal: false,
      snackOpen: false,
      snackSuccess: "success",
      snackMessage: "",
    };
  }

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  checkIfTokenValid = async () => {
    const myInit = {
      headers: { Authorization: false },
      body: {
        username: this.props.userEmail,
      },
    };
    console.log(myInit);
    const resp = await API.post("O365", "/checkToken", myInit);
    console.log("*** check Token response ***");
    console.log(resp);
    if (resp.tokenValid) {
      this.setState({ validToken: true });
    }
  };

  componentDidMount = async () => {
    this.showBackdrop();
    this.setState({ newTokenButtonDisabled: true });
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    console.log(code);
    console.log(this.props.userEmail);

    while (this.props.userEmail == "...") {
      await this.sleep(500);
    }

    if (!this.state.validToken) {
      await this.checkIfTokenValid();
    }

    if (code) {
      this.showBackdrop();
      this.setState({ newTokenButtonDisabled: true });
      const myInit = {
        headers: { Authorization: false },
        body: {
          auth_url: window.location.href,
          username: this.props.userEmail,
          redirect: "https://prod-toolkit.lumen.bio/lab_instrument_file_setup",
        },
      };
      console.log(myInit);
      try {
        const resp = await API.post("O365", "/saveToken", myInit);
        console.log("*** save Token response ***");
        console.log(resp);
        if (resp.message == "success") {
          this.showSnack(true, "Office 365 successfully linked.", "success");
          this.checkIfTokenValid();
        } else {
          this.showSnack(true, "Office 365 did not link successfully", "error");
        }
      } catch {
        this.showSnack(true, "Office 365 did not link successfully", "error");
        this.setState({ newTokenButtonDisabled: false });
        this.hideBackdrop();
      }
    }
    this.setState({ newTokenButtonDisabled: false });
    this.hideBackdrop();
  };

  handleNewTokenButton = async () => {
    this.showBackdrop();
    this.setState({ newTokenButtonDisabled: true });

    const myInit = {
      headers: { Authorization: false },
      queryStringParameters: {
        username: this.props.userEmail,
        redirect: "https://prod-toolkit.lumen.bio/lab_instrument_file_setup",
      },
    };
    console.log(myInit);

    try {
      const resp = await API.get("O365", "/getUrl", myInit);
      console.log("**GET URL RESPONSE**");
      console.log(resp);
      if (resp) {
        console.log(resp.url);
        window.location.href = resp.url;
      } else {
        throw "No O365 auth URL returned.";
      }
    } catch (error) {
      console.log(error);
      this.showSnack(
        true,
        "An undetermined error occurred.  If this reoccurs please reach out for assistance.",
        "error"
      );

      this.setState({ newTokenButtonDisabled: false });
      this.hideBackdrop();
    }
  };

  /* Sign Out logic */
  handleSignOutShow = () => {
    this.setState({ showSignOutModal: true });
  };

  handleSignOutCancel = () => {
    this.setState({ showSignOutModal: false });
  };

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
  };

  showSnack = async (open, message, success) => {
    this.setState({
      snackOpen: open,
      snackMessage: message,
      snackSuccess: success,
    });
  };

  showBackdrop = () => {
    this.setState({ showBackdrop: true });
  };
  hideBackdrop = () => {
    this.setState({ showBackdrop: false });
  };

  handlePrintLabelFormOpen = (open) => {
    console.log("handlePrintLabelFormOpen");
    this.setState({ printLabelFormOpen: open });
  };

  render() {
    let content;
    if (this.state.validToken) {
      content = (
        <>
          <h4 className={"successTitle"}>
            Office 365 is connected to Lab Instrument File System
          </h4>
        </>
      );
    } else {
      content = (
        <>
          <h4 className={"title"}>
            Please link your Office 365 account to Lumen's system below. This
            allows lab instrument files to flow to your OneDrive.
          </h4>
          <div id={"center"}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleNewTokenButton}
              disabled={this.state.newTokenButtonDisabled}
            >
              Link Office 365 to Lab Instrument File System
            </Button>
          </div>
        </>
      );
    }

    return (
      <div className={"wrapper"}>
        <h1 className={"title"}>Lab Instrument File Setup (beta)</h1>
        <br />
        <br />
        <br />
        <br />
        {content}
        <div className={"o365-footer"}>
          <Portal>
            <Snackbar
              open={this.state.snackOpen}
              onClose={this.handleSnackClose}
              autoHideDuration={30000}
            >
              <Alert
                onClose={this.handleSnackClose}
                severity={this.state.snackSuccess}
              >
                {this.state.snackMessage}
              </Alert>
            </Snackbar>
          </Portal>
        </div>
        <Backdrop open={this.state.showBackdrop}>
          <CircularProgress color="primary" />
        </Backdrop>
      </div>
    );
  }
}

export default O365CredentialCapture;
